<template>
  <div>
    <sb-spacer height="20" />

    <sb-divider title="Cursusgroep wijzigen van Begeleider" />

    <transfer v-if="allCourseGroups" :data="allCourseGroups" :target-keys="selectedCourseGroupIds"
      :titles="['Beschikbare cursusgroepen', 'Cursusgroepen van coach']" :render-format="renderTransferItem"
      :operations="['Groep uit', 'Groep in']" :filter-placeholder="'Zoek op naam'" filterable
      @on-change="onCourseGroupTransferChange" />

    <sb-spacer height="400" />

    <sb-bottom-fixed-buttons>
      <template #left>
        <i-button size="large" @click="$router.navigateBack"> Terug </i-button>
      </template>

      <template #right>
        <i-button type="primary" size="large" @click="handleSubmit">
          Opslaan
        </i-button>
      </template>
    </sb-bottom-fixed-buttons>
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons';

import { getResourceId } from '@/lib/user-context-manager';

import CoachCourseGroups from '@/graphql/queries/CoachCourseGroups.gql';
import UpdateGroupsOfCoach from '@/graphql/mutations/UpdateGroupsOfCoach.gql';
import { GraphQL } from '@/lib/graphql';

export default {
  components: {
    SbDivider,
    SbBottomFixedButtons,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    organisationId: {
      type: String,
      default: null,
    },
    resourceId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedCourseGroupIds: [],
      originalCourseGroupIds: [],
    };
  },

  mounted() {
    // groups that are currently assigned to coach
    this.selectedCourseGroupIds =
      this.data.coachingGroups.map((group) => group.id) || [];
    this.originalCourseGroupIds = JSON.parse(
      JSON.stringify(this.selectedCourseGroupIds),
    );
  },

  apollo: {
    // all coursegroups of current resource
    allCourseGroups: {
      query: CoachCourseGroups,
      fetchPolicy: 'cache-and-network',
      variables() {
        return {
          first: GraphQL.MAX_SAFE_INTEGER,
          id: this.organisationId || this.resourceId || getResourceId(),
        };
      },
      update(data) {
        return data.getOrganisationById.groups.edges
          .map((edge) => edge.node)
          .map((group) => ({ key: group.id, ...group }));
      },
    },
  },

  methods: {
    onCourseGroupTransferChange(newTargetKeys) {
      this.selectedCourseGroupIds = newTargetKeys;
    },

    renderTransferItem(item) {
      return item.name;
    },

    async handleSubmit() {
      const disconnectGroupIds = this.originalCourseGroupIds.filter(
        (id) => !this.selectedCourseGroupIds.includes(id),
      );
      const connectGroupIds = this.selectedCourseGroupIds.filter(
        (id) => !this.originalCourseGroupIds.includes(id),
      );

      const { data } = await this.$apollo.mutate({
        mutation: UpdateGroupsOfCoach,
        variables: {
          id: this.data.id,
          disconnectGroupIds,
          connectGroupIds,
        },
      });

      console.log({
        data,
      });

      this.$Message.success('De cursusgroepen zijn succesvol toegewezen');
    },
  },
};
</script>

<style></style>
